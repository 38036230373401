/* You can add global styles to this file, and also import other style files */

//Volvo Fonts
@font-face {
    font-family: VolvoBroad;
    src: url(assets/fonts/VolvoBroadPro.otf) format("opentype");
}

@font-face {
    font-family: VolvoNovum;
    src: url(assets/fonts/VolvoNovum-Regular.otf) format("opentype");
}

@font-face {
    font-family: VolvoNovumBold;
    src: url(assets/fonts/VolvoNovum-Medium.otf) format("opentype");
}

html, body { height: 100%; }
body { margin: 0; font-family: "VolvoNovum", "Helvetica Neue", sans-serif; }


//Icons font
@font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    src: url(assets/fonts/MaterialSymbolsOutlined.woff2) format('woff2');
}

.mat-icon,
.material-symbols-outlined {
    font-family: 'Material Symbols Outlined', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &.small{
        height: 16px;
        width: 16px;
        font-size: 16px;
    }
}

//Text
@black: #333333;
@white: #f7faff;
@secondaryText:#326aa9;

//Background
@primary:#f7faff;
@secondary: #9fbee1;
@tertiary: #e27a00;
@grey: #a6aaaf;
@lightgrey:#dfdfdf;
@page-bg:#dfdfdf;

//Feedback
@red:#D2222D;
@green:#238823;

//Shadow
@shadow:0px 0px 15px #96a1b3;

//Responsive size
@tablet:1000px;
@phone:600px;


//ScrollBar
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-thumb {
    background-color: @secondary;
    border-radius: 20px;
    border: 3px solid @primary;
}

*::-webkit-scrollbar-track {
    background: @primary;
}

mat-option{
    font-family: VolvoNovum, sans-serif;

    &.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled){
        background-color: fade(@secondary, 60%);
    }

    &.mat-option.mat-selected:not(.mat-option-multiple){
        background-color: @secondary !important;
    }

    &.mat-option.mat-active{
        background-color: fade(@secondary, 20%);
    }
}

button {
    all: unset;
    border-radius: 5px;
    width: max-content;
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 24px;
    font-family: "VolvoNovum", sans-serif;
    cursor: pointer;

    &:hover:enabled{
        transform: scale(1.05);
        box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
    }

    &.secondary{
        background-color: @secondary;
        color: @black;

        &:disabled, &[disabled]{
            background-color: fade(@secondary, 50%);
            color: @primary;
        }
    }
    
    &.tertiary{
        background-color: @tertiary;
        color: @white;

        &:disabled, &[disabled]{
            background-color: fade(@tertiary, 50%);
            color: @primary;
        }
    }
    
    &.grey{
        background-color: @grey;
        color: @black;

        &:disabled, &[disabled]{
            background-color: fade(@grey, 50%);
            color: @primary;
        }
    }    
}

//CheckBox
input[type=checkbox] {
    position: relative;
    cursor: pointer;
    width: 24px;
    height: 20px;
    margin: 1px 1px;

    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: -1px;
        left: -1px;
        border: 2px solid @secondary;
        border-radius: 3px;
        background-color: @primary;
    }

    &:checked:before {
        background-color: @secondary;

        &:disabled{
            background-color: @lightgrey;
        }
    }

    &:checked:after {
        content: "";
        display: block;
        width: 6px;
        height: 13px;
        border: solid @primary;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 1px;
        left: 6px;
    }

    &.indeterminate:before {
        background-color: @secondary;
    }

    &.indeterminate:after {
        content: "";
        background-color: @primary;
        display: block;
        position: absolute;
        width: 16px;
        height: 4px;
        top: 9px;
        left: 3px;
        border-radius: 2px;
    }

    &:disabled{
        cursor: not-allowed;

        &:before{
            border: 2px solid @lightgrey;
        }

        &:checked:before {
            background-color: @lightgrey;
        }

        &:checked:after{
            border: solid @lightgrey;
        }
    }
}

.mat-select-panel#timezone-panel{
    max-width: 360px;
}

.mdc-switch:enabled .mdc-switch__track::after{
    background-color: @secondary !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
    background-color: @secondary !important;   
}

.mat-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
    background-color: @secondary;
}

.mat-slide-toggle.mat-mdc-slide-toggle-checkedd:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: @secondary;
}

.mat-slide-toggle.mat-mdc-slide-toggle-checked .mat-ripple-element {
    background-color: @tertiary;
}

mat-slide-toggle input[type=checkbox] {
    position: auto;
    &:before, &:after{
        display: none !important;
    }
}

//SPINNER
mat-progress-spinner circle, mat-spinner circle {
    stroke: @secondary;
}
mat-progress-spinner.primary circle, mat-spinner.primary circle {
    stroke: @primary;
}